
import _ from 'lodash';
import { mergeDeepExceptArrays } from '../../../utils/objectUtils';
import {VisualizationType, visualizationTypes} from './visualizationTypes';


export class VisualizationDefinition {
  private _spec: any;
  private _id: any;
  private _type: VisualizationType;
  private _params: any;
  private _layout: any;
  private _style: any;
  private _darkTheme: any;
  private _uiParams: any;
  get uiParams(){return this._uiParams};
  get params(){return this._params};
  get type(){return this._type};
  get id(){return this._id};
  get style(){return this._style};
  get layout(){return this._layout};
  get spec(){return this._spec};
  constructor(spec) {
    const type = spec['@type'];

    if (_.isNil(type)) {
      throw new Error(`Invalid visualization spec : @type is not set`);
    }

    if (!visualizationTypes.exists(type)) {
      throw new Error(`Visualization type ${type} doesn't exist`);
    }

    this._spec = _.cloneDeep(spec || {});

    if (_.isNil(this._spec.params)) {
      this._spec.params = {};
    }
    if (_.isNil(this._spec.uiParams)) {
      this._spec.uiParams = {};
    }

    this._id = this._spec.id;
    this._type = visualizationTypes.get(type);
    this._params = _.merge({}, this._type.defaultParams ? this._type.defaultParams(this) : undefined, this._spec.params);
    this._uiParams = mergeDeepExceptArrays(this._type.defaultUiParams ? this._type.defaultUiParams(this) : undefined, this._spec.uiParams);
    this._layout = this._spec.layout;
    this._style = this._spec.style;
    this._darkTheme = this._spec.darkTheme;
  }

  mergeParams(params) {
    this._params = _.assign({}, this._params, params);
    this._spec.params = _.assign({}, this._spec.params, params);
  }

  mergeUiParams(uiParams) {
    this._uiParams = _.assign({}, this._uiParams, uiParams);
    this._spec.uiParams = _.assign({}, this._spec.uiParams, uiParams);
  }

  getComponent() {
    return this._type.component;
  }

  getTitle() {
    return this._type.title;
  }

  isEditable() {
    return !_.isUndefined(this._type.editor);
  }

  isExportable() {
    return this._type.editable;
  }

  getEditor() {
    return this._type.editor;
  }
}
