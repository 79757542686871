import {Spinner, SpinnerSize} from '@blueprintjs/core';
import {Tonality} from '@eptica/vecko-js-commons';
import _ from 'lodash';
import React, {ReactElement} from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {SatTypeEnum} from '../../../../application/model/SatTypeEnum';
import {services} from '../../../../application/service/services';
import {SmartIcon} from '../../../../component/icon/SmartIcon';
import {SwitchButton} from '../../../../component/SwitchButton';
import {
    SelectMode,
    Table,
    TableDataset,
    TableDatasetDisplay,
    TableRendererOrJSXElement
} from '../../../../component/table/Table';
import {arrayRetainAll} from '../../../../utils/collectionUtils';
import {Metric, MetricEnum} from '../../../model/MetricEnum';
import {VisualizationInstance} from '../../../model/viz/VisualizationInstance';
import {getDashboardResolvedParams} from '../../../state/selectors/dashboardSelector';
import {TrendIcon} from '../../components/trend/TrendIcon';
import './rankingViz.scss';
import {ResolvedParams} from "../../../model/params/resolve/ResolvedParams";
import {ClickableViz} from "../ClickableViz";
import {FieldValue} from "../../../../application/model/field/FieldValue";
import {FieldVizFeedbacksQueryContext, VizFeedbacksQueryContext} from "../../../model/viz/VizFeedbacksQueryContext";

interface ValueToDisplay {
    metric: Metric,
    value: any
}

interface MultiMetricCellProps {
    metricsToDisplay: Array<Metric>;
    values: any;
    renderMetricValue: (m: Metric, v: ValueToDisplay) => ReactElement;
}

class MultiMetricCell extends React.PureComponent<MultiMetricCellProps> {

    render() {
        const {metricsToDisplay, values, renderMetricValue} = this.props;

        const valuesToDisplay: ValueToDisplay[] = metricsToDisplay
            .map(m => ({metric: m, value: values[m.name]}))
            .filter(o => !_.isNil(o.value));

        if (valuesToDisplay.length === 0) {
            return null;
        }
        if (valuesToDisplay.length === 1) {
            return renderMetricValue(valuesToDisplay[0].metric, valuesToDisplay[0].value);
        }

        return <div className="multi-metric-cell">
            <div>
                {
                    valuesToDisplay.map(o => <div key={o.metric.name}>{renderMetricValue(o.metric, o.value)}</div>)
                }
            </div>
        </div>;
    }
}

const indexRenderer = (rowValue) => {
    return rowValue.rank;
};

const metricRenderers: { [key: string]: (value: any) => ReactElement } = {
    [MetricEnum.SAT_SCORE.name]: v => v,
    [MetricEnum.TREND.name]: v => <TrendIcon value={v} size={20}/>,
    [MetricEnum.VOLUME.name]: v => v,
    [MetricEnum.VOLUME_TREND.name]: v => <TrendIcon value={v} size={20}/>,
    [MetricEnum.PERCENTAGE.name]: v => _.round(v, MetricEnum.PERCENTAGE.precision) + '%',
    [MetricEnum.POSITIVE_OPINIONS.name]: v => <><SmartIcon icon="tag" color={Tonality.POSITIVE.color}/>{v}</>,
    [MetricEnum.NEGATIVE_OPINIONS.name]: v => <><SmartIcon icon="tag" color={Tonality.NEGATIVE.color}/>{v}</>,
};

const renderMetricValueFn = (m: Metric, v) => {
    return metricRenderers[m.name](v);
};

const renderers = {
    category: (field) => (rowValue) => {
        return <span style={{fontSize: "16px"}}>
      {services.getI18nService().translateSilently(`${field}/${rowValue.category}`, rowValue.category)}
    </span>
    },
    metric: (metricsToDisplay) => (rowValue) => {
        return <MultiMetricCell metricsToDisplay={metricsToDisplay}
                                values={rowValue.values}
                                renderMetricValue={renderMetricValueFn}
        />;
    }
};

interface StateProps {
    clickItem?: any;
    showMore: boolean,
    inProgress: boolean,
    data?: any,
    oldData?: any
};


interface RankingVizProps extends WithTranslation {
    viz: VisualizationInstance,
    dashboardResolvedParams: ResolvedParams,
    openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => void
}

export class RankingVizComponent extends React.PureComponent<RankingVizProps> implements ClickableViz {


    state: StateProps = {
        showMore: false,
        inProgress: false
    };

    static getDerivedStateFromProps = (props, state) => {
        const {data} = props;
        if (!state.showMore) {
            return {...state, data: data};
        }

        return state;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // when viz data changes, we reset the showMore state to false.
        if (!_.isEqual(prevProps.data, this.props.viz.data)) {
            this.setState({showMore: false});
        }
    }

    onSelectionChange(selection: Array<any>, newlySelectedItem: any, event: MouseEvent) {
        this.state.clickItem = newlySelectedItem;
        const {t, viz} = this.props;
        this.props.openFeedbacksPanel(this, t(viz.definition.params.field + `/` + newlySelectedItem.category, newlySelectedItem.category) as string);
        event.preventDefault();
        event.stopPropagation();
    }

    setData(data) {
        this.setState({inProgress: false, data: data});
    }

    getData() {
        return this.state.data;
    }

    toggleShow = () => {
        const {viz, dashboardResolvedParams} = this.props;

        this.state.showMore = !this.state.showMore;
        if (this.state.showMore) {
            this.setState({...this.state, inProgress: true, oldData: viz.data}, () => {
                services.getDashboardService().getVizInstanceData(viz, dashboardResolvedParams, {count: 100})
                    .then(vizData => this.setData(vizData))
                    .catch((error) => {
                        this.setState({...this.state, error: error});
                    });
            });
        } else {
            this.setData(this.state.oldData);
        }
    };

    getParams() {
        const {viz} = this.props;
        return viz.definition.params;
    }

    shouldDisplayMetric(metric: Metric) {
        return this.props.viz.definition.uiParams.metrics.includes(metric.name);
    }

    metricColumnsRenderer(): Array<TableDatasetDisplay<any>> {
        const {viz, t} = this.props;
        const params = viz.definition.params;
        const satType = SatTypeEnum.valueOf(params.satType);

        const columns: any = {
            volume: {
                headerRenderer: _.capitalize(t('dashboard.viz.ranking.header.count')),
                metrics: [MetricEnum.VOLUME, MetricEnum.VOLUME_TREND],
                style: {
                    width: 150
                }
            },
            percentage: {
                headerRenderer: _.capitalize(t('dashboard.viz.ranking.header.percentage')),
                metrics: [MetricEnum.PERCENTAGE],
                style: {
                    width: 150
                }
            },
            opinions: {
                headerRenderer: _.capitalize(t('dashboard.viz.ranking.header.opinions')),
                metrics: [MetricEnum.POSITIVE_OPINIONS, MetricEnum.NEGATIVE_OPINIONS],
                style: {
                    width: 100
                }
            }
        };

        if (!_.isNil(satType)) {
            columns.satScore = {
                headerRenderer: _.capitalize(t('dashboard.viz.ranking.header.value', {unit: satType.max})),
                metrics: [MetricEnum.SAT_SCORE, MetricEnum.TREND],
                style: {
                    width: 100
                }
            };
        }
        const metricsToDisplay = this.props.viz.definition.uiParams.metrics.map(m => MetricEnum.valueOf(m));

        return metricsToDisplay
            // find first column with metric
            .map(m => Object.entries(columns).find(([, spec]) => (spec as any).metrics.includes(m))[0])
            // remove duplicates
            .filter((v, i, a) => a.indexOf(v) === i)
            .map(col => {
                const columnSpec = columns[col];
                return {
                    headerRenderer: columnSpec.headerRenderer,
                    dataRenderer: renderers.metric(arrayRetainAll(metricsToDisplay, columnSpec.metrics)),
                    style: columnSpec.style
                };
            });
    }

    getUiDataset(): TableDataset<any> {
        const {viz, t} = this.props;
        const params = viz.definition.params;

        return {
            data: this.getData().items,
            display: [
                {
                    headerRenderer: t(params.field) as string,
                    dataRenderer: (renderers.category(params.field) as any as TableRendererOrJSXElement<any>),
                    style: {
                        flex: 1
                    }
                },
                ...this.metricColumnsRenderer(),
            ]
        };
    }

    renderTable(uiParams) {
        return <Table color={uiParams.color}
                      separatorColor={uiParams.color}
                      dataset={this.getUiDataset()}
                      selectMode={SelectMode.ROW}
                      selectionChanged={this.onSelectionChange.bind(this)}
                      index={{enabled: !uiParams.hideRanks, renderer: indexRenderer}}
                      cellCanSelect={true}
                      selectable={true}
                      highlight={
                          {
                              highlightedClassName: 'highlightedCell',
                              isHighlighted: (rowValue, rowIndex) => rowValue.highlighted
                          }
                      }
        />;
    }

    render() {
        const {t, viz} = this.props;
        const {showMore, inProgress} = this.state;
        const uiParams = viz.definition.uiParams;

        return <div className="ranking">
            <div className="ranking-table-wrapper">
                {
                    inProgress ? <Spinner size={SpinnerSize.SMALL}/> : this.renderTable(uiParams)
                }
            </div>

            {
                uiParams.canSeeMore ? <div>
                    <SwitchButton isOn={showMore}
                                  onChange={this.toggleShow}
                                  onLabel={t('action.showLess')}
                                  offLabel={t('action.showMore')}
                                  buttonProps={{minimal: true, className: 'no-print', icon: 'search'}}
                    />
                </div> : null
            }
        </div>;
    }

    getClickedFieldValue(): FieldValue {
        if (this.state.clickItem) {
            const {viz} = this.props;
            return services.getFieldsService().findFieldValue(viz.definition.params.field, this.state.clickItem.category);
        }
        return null;
    }

    getFeedBacksQueryContext(): VizFeedbacksQueryContext {
        const result = new FieldVizFeedbacksQueryContext();
        if (this.state.clickItem) {
            const {viz, t} = this.props;
            result.field = viz.definition.params.field;
            result.selectedValue = this.state.clickItem.category;
        }
        return result;
    }
}


const mapStateToProps = (state) => {
    return {
        dashboardResolvedParams: getDashboardResolvedParams(state)
    };
};


const mapDispatchToProps = (dispatch, props) => {
    return {
        openFeedbacksPanel: (viz: ClickableViz, panelTitle: string) => {
            dispatch.dashboard.openFeedBackPanel({viz, panelTitle});
        },
    }
};
export const RankingViz = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withTranslation(undefined, {withRef: true})(RankingVizComponent));
