import {Tonality} from '@eptica/vecko-js-commons';
import _ from 'lodash';
import {BoolFilter} from '../../utils/query/filter/BoolFilter';
import {Highlight} from '../../utils/query/Highlight';
import {SearchResult} from '../../utils/query/SearchResult';
import {Sort} from '../../utils/query/Sort';
import {ChannelKind, ChannelKindEnum} from '../model/ChannelKindEnum';
import {FieldName} from '../model/field/Field';
import {FieldDisplay, FieldDisplayKindType} from '../model/field/FieldDisplay';
import {VeckoFields} from '../model/field/VeckoFields';
import {GlobalSatisfaction, GlobalSatisfactionEnum} from '../model/GlobalSatisfaction';
import {services} from './services';
import {SearchQuery} from "../../utils/query/SearchQuery";
import {FeedbackDTO, FeedbackMetadataType, OffsetDTO, VerbatimDTO} from "../model";
import {DateTime} from "luxon";


export class FeedbackService {

    static FEEDBACK_FIELDS = [
        VeckoFields.DATE,
        VeckoFields.VERBATIMS,
        VeckoFields.GLOBAL_SATISFACTION,
        FieldName.standardMetadata('channelKind'),
        FieldName.standardMetadata('channelName')
    ];

    _feedbackFetcher;
    _exportFetcher;

    init() {
        this._feedbackFetcher = services.getFetcherService().getFetcher('feedback');
        this._exportFetcher = services.getFetcherService().getFetcher('export');
    }


    isAuthorizedForDelete(): boolean {
        return services.getSecurityService().hasRole('vecko.feedback.delete');
    }

    async getFeedbacks(query: SearchQuery,
                       displayKind: FieldDisplayKindType,
                       includeGlobalFilter: boolean = true): Promise<SearchResult<FeedbackDTO>> {
        query.addField(FeedbackService.FEEDBACK_FIELDS);
        if (!_.isNil(displayKind)) {
            query.addField(services.getFieldsService().getAllFields(displayKind).map(field => field.name));
            query.filter = BoolFilter.must(query.filter, services.getFilterService().getAdditionalFilters(displayKind));
        }
        return this._searchFeedbacks(query, includeGlobalFilter);
    }

    async exportFeedbacks(exportType: string,
                          maxSize: number,
                          fields: Array<string>,
                          query: SearchQuery,
                          displayKind: FieldDisplayKindType,
                          includeGlobalFilter: boolean = true): Promise<void> {
        if (includeGlobalFilter) {
            query.filter = BoolFilter.must(query.filter, services.getFilterService().getFilterForApi(null));
        }
        if (!_.isNil(displayKind)) {
            query.filter = BoolFilter.must(query.filter, services.getFilterService().getAdditionalFilters(displayKind));
        }

        await this._exportFetcher.exportFeedbacks(exportType, 'indexed', null, null, query.filter.toPlainObject(),
            fields, maxSize ? maxSize : 500, services.getI18nService().getCurrentLanguage());
    }

    async deleteFeedbacks(query: SearchQuery, includeGlobalFilter = true): Promise<void> {
        if (includeGlobalFilter) {
            query.filter = BoolFilter.must(query.filter, services.getFilterService().getFilterForApi(null));
        }
        const queryAsPlain = query.toPlainObject();
        await this._feedbackFetcher.deleteFeedbacks(queryAsPlain);
        services.getApplicationService().notifyMessage({
            level: 'SUCCESS',
            text: {
                key: 'feedbacks.delete.request.success',
            }
        });
    }

    async getFeedbackDetailsById(id: string): Promise<any> {
        const fields = new Set(FeedbackService.FEEDBACK_FIELDS);
        services.getFieldsService().getAllFields(FieldDisplay.FieldDisplayKind.DETAILS_VIEW)
            .filter(f => !f.isCategoryTree())
            .forEach(f => fields.add(f.name));

        return this._feedbackFetcher.getFeedback(id, Array.from(fields));
    }

    async getFeedbackDebug(veckoId: string, substream: string, tenant: string): Promise<FeedbackDTO> {
        return this._feedbackFetcher.getFeedbackDebug(veckoId, substream, tenant)
            .then();
    }

    async searchFeedbackDetails(query: SearchQuery, includeGlobalFilter: boolean = true): Promise<SearchResult<FeedbackDTO>> {
        const fields = new Set(FeedbackService.FEEDBACK_FIELDS);
        services.getFieldsService().getAllFields(FieldDisplay.FieldDisplayKind.DETAILS_VIEW)
            .filter(f => !f.isCategoryTree())
            .forEach(f => fields.add(f.name));

        query.fields(Array.from(fields));
        query.highlight = Highlight.HIGHLIGHT_ALL;

        return this._searchFeedbacks(query, includeGlobalFilter);
    }


    private async _searchFeedbacks(query: SearchQuery, includeGlobalFilter = true): Promise<SearchResult<FeedbackDTO>> {
        if (includeGlobalFilter) {
            query.filter = BoolFilter.must(query.filter, services.getFilterService().getFilterForApi(null));
        }

        query.addSort(new Sort(VeckoFields.DATE));
        query.addField(FeedbackService.FEEDBACK_FIELDS);

        const result = await this._feedbackFetcher.getFeedbacks(query.toPlainObject());
        return new SearchResult(result.hits, result.total);
    }

    getChannelKind(feedback: FeedbackDTO): ChannelKind {
        return ChannelKindEnum.valueOf(this.getStandardMetadata(feedback, 'channelKind') as string);
    }

    getChannelName(feedback: FeedbackDTO): string {
        return this.getStandardMetadata(feedback, 'channelName') as string;
    }

    getGlobalSatisfaction(feedback: FeedbackDTO): GlobalSatisfaction {
        const globalSatisfaction = feedback.enhancement?.satisfaction;
        return _.isNil(globalSatisfaction) ? GlobalSatisfactionEnum.NEUTRAL :
            GlobalSatisfactionEnum.valueOf(globalSatisfaction);
    }

    getSatMood(feedback: FeedbackDTO): Maybe<Tonality> {
        return feedback.satMood ? Tonality.getValueOf(feedback.satMood) : undefined;
    }

    getAllStandardMetadata(feedback: FeedbackDTO): Dict<OneOrMany<FeedbackMetadataType>> {
        return feedback.metadata?.standard || {};
    }

    getAllCustomMetadata(feedback: FeedbackDTO): Dict<OneOrMany<FeedbackMetadataType>> {
        return feedback.metadata?.custom || {};
    }

    getStandardMetadata(feedback: FeedbackDTO, name: string): Maybe<OneOrMany<FeedbackMetadataType>> {
        return this.getAllStandardMetadata(feedback)?.[name];
    }

    getRelevantVerbatims(feedback: FeedbackDTO): Array<VerbatimDTO> {
        let result = feedback.verbatims;
        if (this.getChannelKind(feedback)?.conversational) {
            result = feedback.verbatims.filter(verbatim => verbatim.customer);
        }
        return result.filter(v => v.content.length > 0);
    }

    getAllOffsets(feedback: FeedbackDTO): Array<OffsetDTO> {
        return feedback.verbatims?.flatMap(verbatim => verbatim.offsets || []);
    }

    hasOffset(feedback: FeedbackDTO): boolean {
        return this.getAllOffsets(feedback).length > 0;
    }

    canExportFeedbackHighlights(): boolean {
        return services.getSecurityService().hasRole('vecko.export');
    }

    canSeeFeedbacksTotal(): boolean {
        return services.getSecurityService().hasRole('vecko.ui.feedbacksExplorer.showTotal');
    }

    exportHighlights(params) {
        return this._exportFetcher.exportHighlight(services.getFilterService().getFilterForApi(null).toPlainObject(),
            params.categoryTree, params.tonality, params.maxSize, params.lang);
    }
}

services.registerService('feedbackService', new FeedbackService());
