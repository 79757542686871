import * as React from 'react';
import { Message } from './message/Message';
import { Position } from '@blueprintjs/core';
import { services } from "../service/services";

interface ErrorBoundaryState {
  hasError: boolean
}

interface ErrorBoundaryProps {
  children?: React.ReactNode
}

export class ErrorBoundary extends React.PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    hasError: false
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    console.error(errorInfo);
    this.setState({ hasError: true }, () => {
      services.getApplicationService().notifyMessage({
        level: 'ERROR',
        text: {
          key: `error.runtime`
        },
        error: {
          type: 'RUNTIME_UI',
          error: error,
          errorInfo: errorInfo
        }
      })
    });
  }

  render() {
    if (this.state.hasError) {
      return <Message position={Position.TOP} defaultTimeout={0}/>;
    }

    return this.props.children;
  }
}