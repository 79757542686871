import { produce } from 'immer';
import _ from 'lodash';
import { randomString } from '../../../application/fetch/mock/data/randomUtils';
import { services } from '../../../application/service/services';
import { isDeepEqualIgnoreUndefined } from '../../../utils/objectUtils';
import { Dashboard } from "../dashboard/Dashboard";
import { VisualizationDefinition } from "./VisualizationDefinition";
import { TFunction } from "i18next";
import { variableResolverService } from "../../service/variableResolverService";
import { ResolvedParams } from "../params/resolve/ResolvedParams";

const defaultHasData = (data) => {
  if (_.isUndefined(data)) {
    return false;
  }

  if (_.isArray(data) && data.length === 0) {
    return false;
  }

  // noinspection RedundantIfStatementJS
  if (_.isObject(data) && _.isEmpty(data)) {
    return false;
  }

  return true;
};

const applyEdition = (fn, getter, setter, editedData) => {
  if (_.isNil(fn)) {
    return false;
  }

  const object = getter();
  const oldValue = _.cloneDeep(object);
  let innerApplyResult;

  const immutableResult = produce(object, (draft) => {
    innerApplyResult = fn(draft, editedData);
  });
  const result = innerApplyResult ? innerApplyResult : immutableResult;

  const hasChanged = !isDeepEqualIgnoreUndefined(oldValue, result);

  if (hasChanged) {
    setter(result);
  }

  return hasChanged;
};

export class VisualizationInstance {
  readonly instanceId: string
  readonly dashboard: Dashboard;
  readonly definition: VisualizationDefinition;
  data: any;

  constructor(dashboard: Dashboard, visualizationDefinition: VisualizationDefinition, data: any) {
    this.instanceId = randomString(10);

    this.dashboard = dashboard;
    this.definition = visualizationDefinition;
    if (this.definition.type.prepareViz) {
      this.definition.type.prepareViz(visualizationDefinition, data);
    }
    this.data = data;
  }

  get id(): string {
    return this.definition.id;
  }

  get dashboardId(): string {
    return this.dashboard.id;
  }

  isEditable(): boolean {
    if (!this.definition.isEditable()) {
      return false;
    }
    const editor = this.definition.getEditor();
    if (editor.enabled) {
      return editor.enabled(this);
    }

    return true;
  }


  isExportable(): boolean {
    return this.definition.isExportable();
  }

  applyEdition(editedData: any): boolean {
    if (!editedData || !this.definition.isEditable()) {
      return false;
    }

    const editor = this.definition.getEditor();

    const paramsHaveChanged = applyEdition(
      editor.applyOnParams,
      () => this.definition.params,
      v => this.definition.mergeParams(v),
      editedData.params);

    applyEdition(
      editor.applyOnUiParams,
      () => this.definition.uiParams,
      v => this.definition.mergeUiParams(v),
      editedData.uiParams);

    // we cannot apply edition on data when params has changed because in case of new params, we need to recalculate
    // the data from server which could collide with edited data.
    // todo : we should tell the user in case he changes params and when he has also edited data : we should tell him that
    //        the edition he has done on data will be discarded
    if (paramsHaveChanged) {
      return paramsHaveChanged;
    }

    if (editedData.data) {
      applyEdition(
        editor.applyOnData,
        () => this.data,
        v => this.data = v,
        editedData.data);
    }

    return false;
  }

  getTitle(t: TFunction, resolvedParams: ResolvedParams): string {
    const vizTranslateKey = `viz.${this.dashboardId}.${this.id}`;

    const self = this;
    const result = services.getI18nService().translateSilently(vizTranslateKey, () => {
      const title = self.definition.getTitle();

      if (_.isFunction(title)) {
        // noinspection JSValidateTypes
        return title(self, t);
      }

      return t(title);
    });

    if (typeof result === 'string') {
      return variableResolverService.resolveParamInString(result, resolvedParams.asPlainObject());
    }
    return result;
  }

  getEditorTitle(t: TFunction): string {
    if (!this.isEditable()) {
      return null;
    }

    const vizTranslateKey = `viz.${this.dashboardId}.${this.id}`;
    const self = this;
    const result = services.getI18nService().translateSilently(vizTranslateKey, () => {
      const title = self.definition.getEditor().title;

      if (_.isFunction(title)) {
        // noinspection JSValidateTypes
        return title(self, t);
      }

      return t(title);
    });

    if (typeof result === 'string') {
      return _.capitalize(result);
    }
    return result;
  }

  hasData(): boolean {
    const noDataPolicy = this.definition.type.noDataPolicy;

    if (_.isUndefined(noDataPolicy)) {
      return defaultHasData(this.data);
    }

    if (_.isUndefined(noDataPolicy.hasData)) {
      return defaultHasData(this.data);
    }

    return noDataPolicy.hasData(this);
  }
}
