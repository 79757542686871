import { Checkbox, Colors } from '@blueprintjs/core';
import _ from 'lodash';
import React from 'react';
import './table.scss';
import { equalsInAnyOrder, equalsInSameOrder } from '../../utils/collectionUtils';
import { InfiniteCache } from "../../utils/InfiniteCache";
import classnames from 'classnames';

export type TableCellRenderer<T> = (rowValue: T, rowIndex: number, colIndex: number) => JSX.Element;
export type TableHeaderRenderer<T> = (colIndex: number) => JSX.Element;

export type TableRendererOrJSXElement<T, R = (TableCellRenderer<T> | TableHeaderRenderer<T>)> = R | JSX.Element | string;

export interface TableDatasetDisplay<T> {
  headerRenderer?: TableRendererOrJSXElement<T, TableHeaderRenderer<T>>;
  dataRenderer: TableRendererOrJSXElement<T, TableCellRenderer<T>>;
  style?: React.CSSProperties
}

export interface TableDataset<T> {
  data: Array<T>;
  display: Array<TableDatasetDisplay<T>>
}

interface TableIndexProps<T> {
  enabled: boolean;
  columnLabel?: string;
  renderer?: TableCellRenderer<T>;
}

interface TableHighlightProps<T> {
  isHighlighted?: (rowValue: T, rowIndex: number) => boolean;
  highlightedClassName: string;
}

export const SelectMode = {
  CHECKBOX: 'CHECKBOX',
  ROW: 'ROW'
}
type SelectModeType = typeof SelectMode[keyof typeof SelectMode];

interface TableProps<T> {
  style?: React.CSSProperties;
  color?: string;
  separatorColor?: string;
  dataset: TableDataset<T>;
  index?: TableIndexProps<T>;
  highlight?: TableHighlightProps<T>;
  selectable?: boolean;
  selection?: Array<T>;
  selectionChanged?: (newSelection: Array<T>, item: T, event) => void;
  selectMode?: SelectModeType,
  cellCanSelect?: boolean | Array<number>;
  selectedClassName?: string,
  showHeader?: boolean;
  itemEqual?: EqualComparator<T>;
  headerClassName?: string;
  rowClassName?: string;
  itemId: (T) => string;
  onCellDoubleClick: (t:TableUIData<T>) => void;
}

export interface TableUIData<T> {
  raw: T,
  selected: boolean
}

export class Table<T> extends React.Component<TableProps<T>> {
  static defaultProps: Partial<TableProps<any>> = {
    index: {
      enabled: false
    },
    color: Colors.GRAY1,
    selectable: false,
    selection: [],
    cellCanSelect: false,
    selectMode: SelectMode.CHECKBOX,
    showHeader: true,
  };

  selectionCheckboxHandler = new InfiniteCache<TableUIData<T>, (event) => void>(value => (event) => this.changeSelection(value, !this.isSelected(value), event))
  cellClickHandler = new InfiniteCache<TableUIData<T>, (event) => void>(value => (event) => this.changeSelection(value, !this.isSelected(value), event))

  cellDoubleClickHandler = new InfiniteCache<TableUIData<T>, () => void>(value => () => {
    if (this.props.onCellDoubleClick) {
      this.props.onCellDoubleClick(value);
    }
  })

  changeSelection(value: TableUIData<T>, selected, event) {
    value.selected = selected;

    const newSelection = selected ? [...this.props.selection, value.raw] : this.props.selection.filter(s => s !== value.raw);
    this.props.selectionChanged(newSelection, value.raw, event);
  }

  isSelected(value: TableUIData<T>): boolean {
    return value.selected;
  }

  getUiData(data: Array<T>, selection: Array<T>): Array<TableUIData<T>> {
    return (data || [])
      .map(d => ({
        raw: d,
        selected: selection.includes(d)
      }));
  }

  isHighlighted(tableUIData: TableUIData<T>, rowIndex: number) {
    return this.props.highlight?.isHighlighted ? this.props.highlight.isHighlighted(tableUIData.raw, rowIndex) : false;
  }

  shouldComponentUpdate(nextProps: Readonly<TableProps<T>>, nextState: Readonly<any>, nextContext: any): boolean {
    let needRefresh;

    if (this.props.itemEqual) {
      needRefresh = !equalsInAnyOrder(nextProps.selection, this.props.selection, this.props.itemEqual);
    } else {
      needRefresh = !_.isEqual(nextProps.selection, this.props.selection);
    }

    if (needRefresh) {
      return needRefresh;
    }

    if (this.props.itemEqual) {
      needRefresh = !equalsInSameOrder(nextProps.dataset.data, this.props.dataset.data, this.props.itemEqual);
    } else {
      needRefresh = !_.isEqual(nextProps.dataset.data, this.props.dataset.data);
    }

    return needRefresh || !_.isEqual(_.omit(nextProps.dataset.display, _.functions(nextProps.dataset.display)), _.omit(this.props.dataset.display, _.functions(this.props.dataset.display)));
  }

  isCellCanSelect(index: number): boolean {
    if (!this.props.selectable) {
      return false;
    }

    if (this.isRowSelectable()) {
      return true;
    }

    if (typeof this.props.cellCanSelect === 'boolean') {
      return this.props.cellCanSelect;
    }

    if (_.isArray(this.props.cellCanSelect)) {
      return (this.props.cellCanSelect as Array<number>).indexOf(index) !== -1;
    }

    return false;
  }

  renderTableHeader(colIndex: number, className: string,
                    renderer: TableRendererOrJSXElement<T, TableHeaderRenderer<T>>,
                    style: React.CSSProperties = {}): JSX.Element {
    const elementKey = `header-${colIndex}`;

    return <div key={elementKey} className={className} style={{ ...style, backgroundColor: this.props.color }}>
      {this.renderColumnHeader(renderer, colIndex)}
    </div>;
  }

  renderTableCell(key: string, colIndex: number, rowIndex: number, rowId: string, className: string, tableUIData: TableUIData<T>,
                  valueRenderer: TableRendererOrJSXElement<T, TableCellRenderer<T>>,
                  style: React.CSSProperties = {}): JSX.Element {
    const st = { ...style };

    let onClick;
    if (this.isCellCanSelect(colIndex)) {
      st.cursor = 'pointer';
      onClick = this.cellClickHandler.get(tableUIData);
    }

    const elementKey = `${key}-${colIndex}-${rowId}`;
    return <div key={elementKey} className={className} style={st} onClick={onClick}
                onDoubleClick={this.cellDoubleClickHandler.get(tableUIData).bind(this)}>
      {this.renderCell(valueRenderer, colIndex, rowIndex, tableUIData.raw)}
    </div>;
  }

  getData() {
    return this.getUiData(this.props.dataset.data, this.props.selection);
  }

  isCheckboxSelectable() {
    return this.props.selectable && this.props.selectMode === SelectMode.CHECKBOX;
  }

  isRowSelectable() {
    return this.props.selectable && this.props.selectMode === SelectMode.ROW;
  }

  render() {
    const separatorColor = this.props.separatorColor || this.props.color;
    const indexProp = _.defaultsDeep({}, this.props.index, { enabled: false, columnLabel: '#' });


    const renderSelectorCell = (key, colIndex, rowIndex, className, rowValue) => {
      const id = this.generateUniqueId();
      const elementKey = `${key}-${colIndex}-${rowIndex}`;

      return <div key={elementKey} className={className}>
        <Checkbox id={id} name={id} label=''
                  checked={this.isSelected(rowValue)}
                  onChange={this.selectionCheckboxHandler.get(rowValue)}/>
      </div>;
    };

    const tableHeader = this.props.dataset.display.map((display, index) => {
      let className = 'vuiTableCell';
      if (index === 0) {
        className += ' vuiTableCell--first';
      }
      return this.renderTableHeader(index, className, display.headerRenderer, display.style);
    });

    if (indexProp.enabled) {
      tableHeader.splice(0, 0, this.renderTableHeader(-1, 'vuiTableCell vuiTableCell--index', indexProp.columnLabel));
    }
    if (this.isCheckboxSelectable()) {
      tableHeader.splice(0, 0, this.renderTableHeader(-2, 'vuiTableCell vuiTableCell--select', null));
    }

    const rows = this.getData().map((tableUIData, rowIndex) => {
      const rowClassName = ['vuiTableRow', this.props.rowClassName];
      if (this.isHighlighted(tableUIData, rowIndex)) {
        rowClassName.push(this.props.highlight.highlightedClassName);
      }
      if (this.isRowSelectable() && this.isSelected(tableUIData)) {
        rowClassName.push(this.props.selectedClassName);
      }

      const rowId: string = this.props.itemId?.(tableUIData.raw) ?? rowIndex + "";

      return (
        <div key={rowId} className={classnames(rowClassName)} style={{ borderColor: separatorColor }}>
          {this.isCheckboxSelectable()
            ? renderSelectorCell(
              'selector', -2, rowIndex,
              'vuiTableCell vuiTableCell--select',
              tableUIData
            )
            : ''}
          {indexProp.enabled
            ? this.renderTableCell(
              'idx', -1, rowIndex,
              rowId,
              'vuiTableCell vuiTableCell--index',
              tableUIData,
              indexProp.renderer ? indexProp.renderer : rowIndex + 1
            )
            : ''}
          {this.props.dataset.display.map((display, colIndex) => {
            let className = 'vuiTableCell';
            if (colIndex === 0) {
              className += ' vuiTableCell--first';
            }

            return this.renderTableCell('data', colIndex, rowIndex, rowId, className, tableUIData, display.dataRenderer, display.style);
          })}
        </div>
      );
    });

    return <div className="vuiTable" style={this.props.style}>
      {
        this.props.showHeader ?
          <div className={classnames("vuiTableHeader", this.props.headerClassName)}>
            {tableHeader}
          </div> :
          null
      }

      <div className="vuiTableRows">
        {rows}
      </div>
    </div>;
  }

  private renderCell(renderer: TableRendererOrJSXElement<T, TableCellRenderer<T>>, colIndex: number, rowIndex: number, rowValue: T): JSX.Element {
    if (_.isFunction(renderer)) {
      return (renderer as TableCellRenderer<T>)(rowValue, rowIndex, colIndex);
    }

    return renderer as JSX.Element;
  }

  private renderColumnHeader(renderer: TableRendererOrJSXElement<T, TableHeaderRenderer<T>>, colIndex: number): JSX.Element {
    if (_.isNil(renderer)) {
      return null;
    }

    if (_.isFunction(renderer)) {
      return (renderer as TableHeaderRenderer<T>)(colIndex);
    }

    return renderer as JSX.Element;
  }

  private generateUniqueId() {
    return Math.random().toString(36).slice(2);
  }
}
